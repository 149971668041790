/**
 * Copy a text
 *
 * @param text
 */
function copy(text) {
    navigatorClipboardCopy(text);
}

/**
 * Copy the name of an element
 *
 * @param element
 */
function copy_tt(element) {
    copy($(element).attr("name"));
}

/**
 * Copy combined text contents of each element in the set of matched elements, including their descendants.
 * The .text() method cannot be used on form inputs or scripts.
 * - to set or get the text value of input or textarea elements, use the .val() method.
 * - to get the value of a script element, use the .html() method.)
 *
 * @param element
 */
function copy_champ(element) {
    copy($(element).text());
}

/**
 * Copy the value of an input sub element
 *
 * @param element
 */
function copy_champ_cache(element) {
    copy($(element).find("input").val());
}

/**
 * Success callback
 *
 * @param text
 * @param method
 */
function copySuccess(text, method) {
    // let message = "The following text has been copied into the clipboard";
    // if (method) {
    //     message += " (using " + method + ")";
    // }
    // message += ": \"" + text + "\"";
    // console.log(message)

    const copieElm = document.getElementById("copie");
    if (copieElm) {
        // copieElm.innerHTML = "Text has been copied on the clipboard";
        copieElm.innerHTML = "Le texte a été copié dans le presse-papier";
    }
}

/**
 * Failure callback
 *
 * @param text
 * @param method
 * @param errorMessage
 * @param fallbackMethod
 */
function copyFailure(text, method, errorMessage, fallbackMethod) {
    let message = "Unable to copy the following text into the clipboard";
    if (method) {
        message += " (using " + method + ")";
    }
    message += ": \"" + text + "\"";
    if (errorMessage) {
        message += "\nError: " + errorMessage;
    }
    if (fallbackMethod) {
        message += "\n=> use " + fallbackMethod + " as fallback."
    }
    console.error(message);
}

/**
 * Copy text using "navigator.clipboard.writeText" method
 * uses "execCommandCopy" function ("document.execCommand('copy')" method) as fallback
 *
 * @param text
 */
function navigatorClipboardCopy(text) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(function () {
            copySuccess(text, "navigator.clipboard.writeText");
        }, function (err) {
            copyFailure(text, "navigator.clipboard.writeText", err, "document.execCommand('copy')");
            execCommandCopy(text);
        });
    } else {
        copyFailure(text, "navigator.clipboard.writeText", null, "document.execCommand('copy')");
        execCommandCopy(text);
    }
}

/**
 * Copy text using "document.execCommand('copy')" method
 * uses "clipboardDataCopy" function ("window.clipboardData.setData" method) as fallback
 *
 * @param text
 */
function execCommandCopy(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        if (successful) {
            copySuccess(text, "document.execCommand('copy')");
        } else {
            copyFailure(text, "document.execCommand('copy')", null, "window.clipboardData.setData");
            clipboardDataCopy(text);
        }
    } catch (err) {
        copyFailure(text, "document.execCommand('copy')", err, "window.clipboardData.setData");
        clipboardDataCopy(text);
    }
    document.body.removeChild(textArea);
}

/**
 * Copy text using "window.clipboardData.setData" method (for Internet Explorer)
 * uses "alert" function as fallback
 *
 * @param text
 */
function clipboardDataCopy(text) {
    if (window.clipboardData) {
        window.clipboardData.setData("text/plain", text);
        copySuccess(text, "window.clipboardData.setData");
    } else {
        console.error("window.clipboardData isn't available on this browser\n=> use alert() as fallback.");
        alertCopy(text);
    }
}

/**
 * Copy text using an "alert" popup
 *
 * @param text
 */
function alertCopy(text) {
    // alert("Please copy the following text using Ctrl+C (Windows/Linux) or Cmd+C (Mac)\n\n" + text);
    alert("Veuillez copier le texte suivant en utilisant Ctrl+C (Windows/Linux) ou Cmd+C (Mac)\n\n" + text);
}
